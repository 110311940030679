import { useEffect, useState } from "react";
import TaskBaseTable from "../../components/table/task_base_table";

import {getFirestore, getDocs, collection, query, orderBy, getDoc, doc} from 'firebase/firestore';
import {getApp} from 'firebase/app';
import { useNavigate, useSearchParams } from "react-router-dom";
import TaskDetail from "./task_detail";
import Toast from '../../components/common/toast';

const TaskLists = () => {
  const [loading, setIsLoading] = useState(true);
  const [tab, setTab] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [caseNo, setCaseNo] = useState('');
  const [toast, setToast] = useState('');

  useEffect(() => {
    initReq();
  }, []);

  useEffect(() => {
    if (searchParams) {
      const defaultTab = searchParams.get('tab');
      if (!defaultTab) {
        setTab('1');
      } else {
        setTab(defaultTab);
      }

      const queryCaseNo = searchParams.get('caseNo');
      if (queryCaseNo) {
        setCaseNo(queryCaseNo);
      } else {
        setCaseNo('');
      }
    }
  }, [searchParams]);

  const [allLists, setAllLists] = useState([]);

  const initReq = async () => {
    const db = getFirestore(getApp());
    const [cases] = await Promise.all([
      getDocs(query(collection(db, 'insurance_cases'), orderBy('last_updated_at', 'desc'))),
    ]);

    setAllLists(cases.docs.map((doc) => doc.data()).filter((v) => v.user_id !== 'DELETED'));

    setIsLoading(false);
  };

  const updateReq = async (caseNo) => {
    const db = getFirestore(getApp());
    const d = await getDoc(doc(db, 'insurance_cases', caseNo));

    setAllLists(allLists.map((v) => {
      if (v.case_no === caseNo) {
        return d.data();
      }

      return v;
    }));
  };

  const replaceTab = (tab) => {
    navigate('/tasks?tab=' + tab, {replace: true});
  };

  const [keyword, setKeyword] = useState('');

  const filterByKeyword = (v, s) => {
    return v.phone_number.includes(s) || v.proposal_request_info.name.includes(s) || v.case_no.includes(s);
  }


  if (loading) {
    return null;
  }

  if (caseNo) {
    if (allLists.filter((v) => v.case_no === caseNo)[0]) {
      return <TaskDetail updateReq={updateReq} caseData={allLists.filter((v) => v.case_no === caseNo)[0]} />;
    }

    navigate('/tasks', {replace: true});
    setTimeout(() => {
      setToast('deletedCase');
    }, 500);
    return null;
  }

  return (
    <div className="tasklists-container">
      <Toast
        text={'This case is deleted!'}
        start={toast}
        setStart={setToast}
        toastName="deletedCase"
      />
      <div className="table-searchbar-row">
        <input placeholder="Enter keyword..." value={keyword} onChange={({target: {value}}) => setKeyword(value)} />
        <div className="search-btn">
          <img alt="reg" src="/icon/search-light.png" />
          Search
        </div>
      </div>
      <div className="table-tabbar-row">
        <div className={`step center ${tab === '1' ? 'active' : ''}`} onClick={() => replaceTab('1')}>
          All {allLists.length}
        </div>
        <div className="step-wrapper" onClick={() => replaceTab('2')}>
          <div className="circle" />
          <div className={`step center ${tab === '2' ? 'active' : ''}`}>
            Proposal Request {allLists.filter(({step_info}) => step_info.step === 1).length}
          </div>
        </div>
        <div className={`step center ${tab === '3' ? 'active' : ''}`} onClick={() => replaceTab('3')}>
          Proposal Received {allLists.filter(({step_info}) => step_info.step === 2 && step_info.sub_step === 'REC_PROPOSAL').length}
        </div>
        <div className="step-wrapper" onClick={() => replaceTab('4')}>
          <div className="circle" />
          <div className={`step center ${tab === '4' ? 'active' : ''}`}>Application Request {allLists.filter(({step_info}) => step_info.step === 2 && step_info.sub_step === 'REQ_APPLICATION').length}</div>
        </div>
        <div className={`step center ${tab === '5' ? 'active' : ''}`} onClick={() => replaceTab('5')}>Application Received {allLists.filter(({step_info}) => step_info.step === 2 && step_info.sub_step === 'REC_APPLICATION').length}</div>
        <div className="step-wrapper" onClick={() => replaceTab('6')}>
          <div className="circle" />
          <div className={`step center ${tab === '6' ? 'active' : ''}`}>Application Review {allLists.filter(({step_info}) => step_info.step === 3).length}</div>
        </div>
        <div className={`step center ${tab === '7' ? 'active' : ''}`} onClick={() => replaceTab('7')}>Issued {allLists.filter(({step_info}) => step_info.step === 4).length}</div>
      </div>
      {
        tab === '2' ? (
          <div className="tasklists-direction-wrapper">
            <img alt="reg" src="/icon/headphone.png" />
            <div>You need to upload proposal for users!</div>
          </div>
        ) : null
      }
      {
        tab === '4' ? (
          <div className="tasklists-direction-wrapper">
            <img alt="reg" src="/icon/headphone.png" />
            <div>You need to upload application for users!</div>
          </div>
        ) : null
      }
      {
        tab === '6' ? (
          <div className="tasklists-direction-wrapper">
            <img alt="reg" src="/icon/headphone.png" />
            <div>You need to change user's real time status</div>
          </div>
        ) : null
      }
      <TaskBaseTable lists={
        allLists.filter((v) => {
          if (!filterByKeyword(v, keyword)) {
            return false;
          }

          const {step_info} = v;

          if (tab === '1') {
            return true;
          }

          if (tab === '2') {
            return step_info.step === 1;
          }

          if (tab === '3') {
            return step_info.step === 2 && step_info.sub_step === 'REC_PROPOSAL';
          }

          if (tab === '4') {
            return step_info.step === 2 && step_info.sub_step === 'REQ_APPLICATION';
          }

          if (tab === '5') {
            return step_info.step === 2 && step_info.sub_step === 'REC_APPLICATION';
          }

          if (tab === '6') {
            return step_info.step === 3;
          }

          if (tab === '7') {
            return step_info.step === 4;
          }

          return true;
        })
      } />
    </div>
  );
};

export default TaskLists;
