import React from 'react';
import Calendar from 'react-calendar';

const CalendarToModal = ({open, cancel, setDayTo, dayFrom}) => {
  const dayClick = (value) => {
    if(dayFrom) {
      if (dayFrom < value) {
        setDayTo(value);
        alert(value);
      } else {
        alert('큰일날소리');
      }
    } else {
      setDayTo(value);
      alert(value);
    }
  };

  return (
    <>
      {
        open ?
        <div className='modal-overlay'>
          <div className='calendar-modal-container'>
            <Calendar
              calendarType='gregory'
              locale='en'
              view='month'
              prevLabel={<img className='img' src={'/icon/arrow-cal-prev.png'} />}
              nextLabel={<img className='img' src={'/icon/arrow-cal-next.png'} />}
              prev2Label={<img className='img' src={'/icon/arrow2x-cal-prev.png'} />}
              next2Label={<img className='img' src={'/icon/arrow2x-cal-next.png'} />}
              onClickDay={(value, event) => dayClick(value)}
            />
            <div className='upload-btn-cancel calendar-modal' onClick={() => cancel()}>Close</div>
          </div>
        </div> : null
      }
    </>
  );
};

export default CalendarToModal;