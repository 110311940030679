import { getApp } from "firebase/app";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai";

async function fileToGenerativePart(file) {
  const base64EncodedDataPromise = new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve((reader.result).split(',')[1]);
    reader.readAsDataURL(file);
  });
  return {
    inlineData: { data: await base64EncodedDataPromise, mimeType: file.type },
  };
}


const checkProposalData = async (code, file) => {
  const vertexAI = getVertexAI(getApp());
  const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash-002", generationConfig: {temperature: 1, maxOutputTokens: 8192} });

  const v = await fileToGenerativePart(file);

  let prompt = '';
  if (code.includes('AXA')) {
    prompt = '1번째 배열의 들어갈 데이터는 basic plan surrender value 연차별로 json으로 뽑아줘 포맷은 이렇게 "year: 연도, total: total value", 2번째 배열의 들어갈 데이터는 SUPPLEMENTARY ILLUSTRATION SUMMARY surrender value 전체 연차를 "year: 연도, total: total value, withdrawal: withdrawal(매년 반복적)", 최종적으로 [1번째 배열, 2번째 배열]로 return 해줘';
  } else if (code.includes('CHUBB')) {
    prompt = '1번째 배열의 들어갈 데이터는 basic plan surrender value 연차별로 json으로 뽑아줘 포맷은 이렇게 "year: 연도, total: total value", 2번째 배열의 들어갈 데이터는 surrender value 전체 연차를 "year: 연도, total: total value, withdrawal: withdrawal(매년 반복적)", 최종적으로 [1번째 배열, 2번째 배열]로 return 해줘. 설명빼고 배열만 return 해줘';
  } else if (code.includes('ZURICH')) {
    prompt = '1번째 배열의 들어갈 데이터는 3a. Basic plan - Illustration summary에서 찾아야 하고, basic plan Current Assumed basis account value 항목별로 json으로 뽑아줘 포맷은 이렇게 "year: 연도, total: total value", 2번째 배열의 들어갈 데이터는 Basic plan - Supplementary illustration for all years of account values 페이지의 Current assumed basis account value 전체 연차를 "year: 연도, total: total value, withdrawal: withdrawal amount항목에 있는 숫자"로 나타내줘 근데 total premium은 인출금액이 아니야, 최종적으로 [1번째 배열, 2번째 배열]로 return 해줘. null은 모두 0으로 넣어';
  }

  const result = await model.generateContentStream([v, prompt]);

  let resString = '';
  for await (const chunk of result.stream) {
    const chunkText = chunk.text();
    resString += chunkText
  }

  console.log(resString);

  return JSON.parse(resString.replaceAll('```json', '').replaceAll('```', ''));
};

export default checkProposalData;
