import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc, arrayUnion } from "firebase/firestore";

const updateReviewSubstate = async (caseNo, step, subStep, updatedValue) => {
  await Promise.all([
      updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo), {
        step_info: {
          step,
          sub_step: subStep,
        },
        last_updated_at: new Date().getTime(),
        last_updated_at_str: new Date(),
        ...updatedValue,
      }),
      updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo, 'histories', 'histories'), {
        histories: arrayUnion({
          step,
          substep: subStep,
          timestamp: new Date().getTime(),
        }),
      })
    ]);
};

export default updateReviewSubstate;
