import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const uploadFiles = async (caseCode, files) => {
  const storage = getStorage();

  const timestap = new Date().getTime();

  const urls = await Promise.all(files.map(async (file) => {
    const target = ref(storage, `insurein_process/${caseCode}/${timestap}/${file.name}`);
    await uploadBytes(target, file);

    return await getDownloadURL(target);
  }));

  return urls;
};

export default uploadFiles;
