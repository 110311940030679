import { getApp } from "firebase/app";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

const LoginPage = () => {
  const signIn = async () => {
    try {
      const app = getApp();
      const auth = getAuth(app);
      await signInWithEmailAndPassword(auth, document.getElementById('email').value, document.getElementById('password').value);
    } catch (err) {
      console.log(err);
      alert('Incorrect data');
    }
  };

  const handleKeydown = (e) => {
    if (e.keyCode === 13) {
      signIn();
    }
  }

  return (
    <div className="login-page-wrapper">
      <div className="login-page-login-section">
        <img src="/img/home-appbar-logo.avif" alt="home" />
        <div className="login-input-wrapper">
          <div className="label">Email</div>
          <input placeholder="email.." id="email" onKeyDown={handleKeydown} />
        </div>
        <div className="login-input-wrapper">
          <div className="label">Password</div>
          <input placeholder="password..." type="password" id="password" onKeyDown={handleKeydown} />
        </div>
        <div className="login-btn" onClick={signIn}>Login</div>
      </div>
      <div className="login-page-img-section">
        <img alt="reg" src="/img/home5-img.avif" />
      </div>
    </div>
  );
};

export default LoginPage;
