import React from 'react';
import Radio from '../common/radio';

const StateModal = ({open, cancel, save, selected, setSelected}) => {

  const clickItem = (idx) => {
    setSelected(idx);
  };

  return (
    <>
      {
        open ?
        <div className='modal-overlay'>
          <div className='state-modal-container'>
            <div className='state-modal-body'>
              <div className='state-modal-body-title'>Please upload the file, then click 'Save'.</div>
              <div className='state-modal-select-wrap'>
                <div className='state-modal-select-item' onClick={() => clickItem(1)}>
                  <Radio active={selected === 1} /><div className='state-modal-select-item-wrap'>Application Sent via International Mail</div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(2)}>
                  <Radio active={selected === 2} /><div className='state-modal-select-item-wrap'>Received by IFA</div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(3)}>
                  <Radio active={selected === 3} /><div className='state-modal-select-item-wrap'>Received by Trust Company</div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(4)}>
                  <Radio active={selected === 4} /><div className='state-modal-select-item-wrap'>Underwriting in Progress</div>
                </div>
                {/* <div className='state-modal-select-item' onClick={() => clickItem(5)}>
                  <Radio active={selected === 5} /><div className='state-modal-select-item-wrap'>Payment Completed</div>
                </div> */}
                <div className='state-modal-select-item' onClick={() => clickItem(6)}>
                  <Radio active={selected === 6} /><div className='state-modal-select-item-wrap'>Approved</div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(7)}>
                  <Radio active={selected === 7} /><div className='state-modal-select-item-wrap'>Policy Issued by Insurance Company</div>
                </div>
                <div className='state-modal-select-item last' onClick={() => clickItem(8)}>
                  <Radio active={selected === 8} /><div className='state-modal-select-item-wrap'>Policy Sent by IFA</div>
                </div>
                {/* <div className={`state-modal-select-item-input-wrap ${selected === 9 ? 'active' : ''}`}>
                  <div className={`state-modal-select-item ${selected === 9 ? 'active' : ''}`} onClick={() => clickItem(9)}>
                    <Radio active={selected === 9} /><div className='state-modal-select-item-wrap'>Card Approval Declined</div>
                  </div>
                  {
                    selected === 9 ?
                    <input className='text-input state-modal-input' type='text' placeholder='Please enter the reason...' /> : null
                  }
                </div>
                <div className={`state-modal-select-item-input-wrap last ${selected === 10 ? 'active' : ''}`}>
                  <div className={`state-modal-select-item last ${selected === 10 ? 'active' : ''}`} onClick={() => clickItem(10)}>
                    <Radio active={selected === 10} /><div className='state-modal-select-item-wrap'>Policy Sent by IFA</div>
                  </div>
                  {
                    selected === 10 ?
                    <input className='text-input state-modal-input' type='text' placeholder='Please enter the reason...' /> : null
                  }
                </div> */}
              </div>
            </div>
            <div className='upload-modal-foot'>
              <div className='upload-btn-cancel' onClick={() => cancel()}>Cancel</div>
              <div className='upload-btn-save' onClick={() => save()}>Save</div>
            </div>
          </div>
        </div> : null
      }
    </>
  );
};

export default StateModal;