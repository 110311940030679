import { useNavigate } from "react-router-dom";
import getListStepLabel from "../../utils/step/get_list_step_label";

const TaskBaseTable = ({lists}) => {
  const navigate = useNavigate();

  return (
    <div className="table-col">
      <div className="table-header">
        <div className="task-base-col1">Case No.</div>
        <div className="task-base-col2">Product</div>
        <div className="task-base-col4">Premium (y)</div>
        <div className="task-base-col3">Term</div>
        <div className="task-base-col5">Customer</div>
        <div className="task-base-col6">Consultant</div>
        <div className="task-base-col7">Updated</div>
        <div className="task-base-col8">Status</div>
      </div>
      <div className="table-content">
        {
          lists.length === 0 ? (
            <div className="empty-wrapper">
              <img alt="empty" src="/icon/emptytable.avif" className="empty" />
              <div>There are no tasks registered yet.</div>
            </div>
          ) : <div />
        }
        {
          lists.map((v) => {
            const productInfo = window.insurein.products[v.product_code];
            const companyInfo = window.insurein.companies[productInfo.company_code];
            const premium = ((v['application_request_info'] && v['application_request_info']['basic_info']) ? v['application_request_info']['basic_info']['premium_yearly'] : v['proposal_request_info']['premium_yearly']).toLocaleString();
            const term = (v['application_request_info'] && v['application_request_info']['basic_info']) ? v['application_request_info']['basic_info']['premium_term'] : v['proposal_request_info']['premium_term'];
            const name = (v['application_request_info'] && v['application_request_info']['contractor_info']) ? v['application_request_info']['contractor_info']['name'] : v['proposal_request_info'].name;
          
            return (
              <div className="table-item" onClick={() => navigate(window.location.pathname + (window.location.search ? window.location.search + '&' : '?') + 'caseNo=' + v.case_no)}>
                <div className="task-base-col1"><img src={`/icon/flag-${v.nationality.toLowerCase()}.png`} alt="" />{v.case_no}</div>
                <div className="task-base-col2">
                  <img src={companyInfo.icon_url} alt="" />
                  {productInfo['product_name']}
                </div>
                <div className="task-base-col4">{'$' + premium}</div>
                <div className="task-base-col3">{term + ' years'}</div>
                <div className="task-base-col5">{name}</div>
                <div className="task-base-col6">{v['consultant_info']['name']}</div>
                <div className="task-base-col7">{new Date(v['last_updated_at']).toLocaleDateString()}</div>
                <div className="task-base-col8">{getListStepLabel(v.step_info.step, v.step_info.sub_step)}</div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default TaskBaseTable;
