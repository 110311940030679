import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc, arrayUnion } from "firebase/firestore";

const uploadProposal = async (caseNo, files, accountValueTable) => {
  await Promise.all([
    updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo), {
      step_info: {
        step: 2,
        sub_step: 'REC_PROPOSAL',
      },
      proposal_info: {
        account_table_data: accountValueTable[0],
        account_withdrawal_table_data: accountValueTable[1] === null ? null : accountValueTable[1].map((v) => ({...v, withdrawal: v.withdrawal ?? 0})),
        file: files[0],
        file_name: files[0].split('/')[files[0].split('/').length - 1],
      },
      last_updated_at: new Date().getTime(),
      last_updated_at_str: new Date(),
    }),
    updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo, 'histories', 'histories'), {
      histories: arrayUnion({
        step: 2,
        substep: 'REC_PROPOSAL',
        timestamp: new Date().getTime(),
      }),
    })
  ]);
};

export default uploadProposal;
