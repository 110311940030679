import React from 'react';
import Radio from '../common/radio';

const AddConsultantModal = ({open, cancel, save, selected, setSelected, phoneNumber, setPhoneNumber, name, setName, email, setEmail, grade, setGrade}) => {
  const clickItem = (idx) => {
    setSelected(idx);
  };

  return (
    <>
      {
        open ?
        <div className='modal-overlay'>
          <div className='add-consultant-modal-container'>
            <div className='add-consultant-modal-body'>
              <div className='state-modal-body-title'>Enter the consultant's information and click the 'Save' button.</div>
              <div className='add-consultant-modal-wrap'>
                {/* <div className='add-consultant-modal-input'>
                  <div className='add-consultant-label'>Nationality</div>
                  <input className='text-input' type='text' placeholder={`Enter consultant's nationality...`} />
                  <div className='upload-input-valid'>Please upload a file before proceeding to the next step.</div>
                </div> */}
                <div className='add-consultant-modal-input'>
                  <div className='add-consultant-label'>Mobile<span>: numbers only</span></div>
                  <input className='text-input' type='text' placeholder={`Enter consultant's mobile number...`} value={phoneNumber} onChange={({target: {value}}) => setPhoneNumber(value)} />
                  {/* <div className='upload-input-valid'>Please upload a file before proceeding to the next step.</div> */}
                </div>
                <div className='add-consultant-modal-input'>
                  <div className='add-consultant-label'>Name</div>
                  <input className='text-input' type='text' placeholder={`Enter consultant's full name...`} value={name} onChange={({target: {value}}) => setName(value)} />
                  {/* <div className='upload-input-valid'>Please upload a file before proceeding to the next step.</div> */}
                </div>
                <div className='add-consultant-modal-input'>
                  <div className='add-consultant-label'>Email</div>
                  <input className='text-input' type='text' placeholder={`Enter consultant's email address...`} value={email} onChange={({target: {value}}) => setEmail(value)} />
                  {/* <div className='upload-input-valid'>Please upload a file before proceeding to the next step.</div> */}
                </div>
                <div className='add-consultant-modal-input'>
                  <div className='add-consultant-label'>Grade</div>
                  <div className='add-consultant-modal-garde-table'>
                    <div className='add-consultant-modal-grade-row'>
                      <div className='add-consultant-modal-grade-item' onClick={() => setGrade('yellow')}>
                        <Radio active={grade === 'yellow'} />
                        <img src={'/icon/yellow.png'} />
                        <div>Yellow</div>
                      </div>
                      <div className='add-consultant-modal-grade-item' onClick={() => setGrade('white')}>
                        <Radio active={grade === 'white'} />
                        <img src={'/icon/white.png'} />
                        <div>White</div>
                      </div>
                      <div className='add-consultant-modal-grade-item last' onClick={() => setGrade('red')}>
                        <Radio active={grade === 'red'} />
                        <img src={'/icon/red.png'} />
                        <div>Red</div>
                      </div>
                    </div>
                    <div className='add-consultant-modal-grade-row'>
                      <div className='add-consultant-modal-grade-item' onClick={() => setGrade('blue')}>
                        <Radio active={grade === 'blue'} />
                        <img src={'/icon/blue.png'} />
                        <div>Blue</div>
                      </div>
                      <div className='add-consultant-modal-grade-item' onClick={() => setGrade('purple')}>
                        <Radio active={grade === 'purple'} />
                        <img src={'/icon/purple.png'} />
                        <div>Purple</div>
                      </div>
                      <div className='add-consultant-modal-grade-item last' onClick={() => setGrade('black')}>
                        <Radio active={grade === 'black'} />
                        <img src={'/icon/black.png'} />
                        <div>Black</div>
                      </div>
                    </div>
                    <div className='add-consultant-modal-grade-row last'>
                      <div className='add-consultant-modal-grade-item' onClick={() => setGrade('silver')}>
                        <Radio active={grade === 'silver'} />
                        <img src={'/icon/silver.png'} />
                        <div>Silver</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='upload-modal-foot'>
              <div className='upload-btn-cancel' onClick={() => cancel()}>Cancel</div>
              <div className='upload-btn-save' onClick={() => save()}>Save</div>
            </div>
          </div>
        </div> : null
      }
    </>
  );
};

export default AddConsultantModal;