import { useEffect, useState } from "react";

import {getFirestore, getDocs, collection, query, where, orderBy, getDoc, updateDoc, doc, increment} from 'firebase/firestore';
import {getApp} from 'firebase/app';
import { useNavigate, useSearchParams } from "react-router-dom";
import ConsultantBaseTable from "../../components/table/consultant_table";
import AddConsultantModal from "../../components/modal/add_consultant";
import Toast from "../../components/common/toast";
import ConsultantDetail from "./consultant_detail";

const ConsultantList = () => {
  const [loading, setIsLoading] = useState(true);
  const [tab, setTab] = useState('1');
  const navigate = useNavigate();
  const [toast, setToast] = useState('');
  const [searchParams] = useSearchParams();

  const [userId, setUserId] = useState('');

  useEffect(() => {
    initReq();
  }, []);

  useEffect(() => {
    if (searchParams) {
      const defaultTab = searchParams.get('tab');
      if (!defaultTab) {
        setTab('1');
      } else {
        setTab(defaultTab);
      }

      const queryUserId = searchParams.get('userId');
      if (queryUserId) {
        setUserId(queryUserId);
      } else {
        setUserId('');
      }
    }
  }, [searchParams]);

  const [allLists, setAllLists] = useState([]);

  const initReq = async () => {
    const db = getFirestore(getApp());
    const [users] = await Promise.all([
      getDocs(query(collection(db, 'users'), orderBy('consultant_info.id'))),
    ]);

    setAllLists(users.docs.map((doc) => doc.data()).sort((b, a) => a.created_at - b.created_at));

    setIsLoading(false);
  };

  const replaceTab = (tab) => {
    navigate('/consultants?tab=' + tab, {replace: true});
  };
  
  const [addConsultantModal, setAddConSultantModal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(1);
  const openAddConsultantModal = () => {
    setAddConSultantModal(true);
  };
  const addConsultantModalCancel = () => {
    setAddConSultantModal(false);
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [grade, setGrade] = useState('yellow');

  const updateReq = async (userId) => {
    const db = getFirestore(getApp());
    const ds = await getDocs(query(collection(db, 'users'), where('consultant_info.id', '==', userId)));

    const data = ds.docs.map((d) => d.data())[0];

    setAllLists([...allLists, data]);
  };

  const addConsultantModalSave = async () => {
    const db = getFirestore(getApp());
    const [ds, total] = await Promise.all([
      getDocs(query(collection(db, 'users'), where('phone_number', '==', '+82' + phoneNumber.substring(1)))),
      getDoc(doc(db, 'info', 'consultant_total')),
    ]);

    const dss = ds.docs.map((d) => d.data());
    if (dss.length === 0) {
      alert('유저 정보가 없습니다');
      return;
    }

    const id = dss[0].user_id
    await Promise.all([
      updateDoc(doc(db, 'users', id), {
        permit_consultant_mode: true,
        consultant_info: {
          consultant_id: 'KR-' + (total.data().kr + 1).toString().padStart(5, '0'),
          email,
          grade,
          id,
          name,
          phone_number: phoneNumber,
        },
      }),
      updateDoc(doc(db, 'info', 'consultant_total'), {
        kr: increment(1),
      })
    ]);

    setAddConSultantModal(false);
    setToast('addConsultant');

    await updateReq(id);
  };

  const [keyword, setKeyword] = useState('');

  const filterByKeyword = (v, s) => {
    return v.consultant_info.phone_number.includes(s) || v.consultant_info.email.includes(s) || v.consultant_info.id.includes(s) || v.consultant_info.name.includes(s);
  }

  if (loading) {
    return null;
  }

  if (userId) {
    return <ConsultantDetail userData={allLists.filter((v) => v.user_id === userId)[0]} />;
  }

  return (
    <div className="tasklists-container">
      <AddConsultantModal
        open={addConsultantModal}
        cancel={addConsultantModalCancel}
        save={addConsultantModalSave}
        selected={selectedGrade}
        setSelected={setSelectedGrade}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        grade={grade}
        setGrade={setGrade}
      />
      <Toast
        text={'A new consultant has been added.'}
        start={toast}
        setStart={setToast}
        toastName="addConsultant"
      />
      <div className="table-searchbar-row-between">
        <div className="table-searchbar-row">
          <input placeholder="Enter keyword..." value={keyword} onChange={({target: {value}}) => setKeyword(value)} />
          <div className="search-btn">
            <img alt="reg" src="/icon/search-light.png" />
            Search
          </div>
        </div>
        <div className="add-consultant-btn" onClick={() => openAddConsultantModal()}>
          <img alt="reg" src="/icon/add-consultant.png" />
          Add Consultant
        </div>
      </div>
      <div className="table-tabbar-row">
        <div className={`step center ${tab === '1' ? 'active' : ''}`} onClick={() => replaceTab('1')}>All {allLists.length}</div>
        <div className="step-wrapper" onClick={() => replaceTab('2')}>
          <div className={`step center ${tab === '2' ? 'active' : ''}`}>South Korea {allLists.filter((v) => v.nationality === 'KR').length}</div>
        </div>
        <div className={`step center ${tab === '3' ? 'active' : ''}`} onClick={() => replaceTab('3')}>Hong Kong {allLists.filter((v) => v.nationality === 'HK').length}</div>
        <div className="step-wrapper" onClick={() => replaceTab('4')}>
          <div className={`step center ${tab === '4' ? 'active' : ''}`}>Japan  {allLists.filter((v) => v.nationality === 'JP').length}</div>
        </div>
        <div className="step-wrapper" onClick={() => replaceTab('5')}>
          <div className={`step center ${tab === '5' ? 'active' : ''}`}>China  {allLists.filter((v) => v.nationality === 'CN').length}</div>
        </div>
        <div className={`step center ${tab === '6' ? 'active' : ''}`} onClick={() => replaceTab('6')}>Taiwan  {allLists.filter((v) => v.nationality === 'TW').length}</div>
      </div>
      <ConsultantBaseTable lists={
        allLists.filter((v) => {
          if (!filterByKeyword(v, keyword)) {
            return false;
          }

          if (tab === '1') {
            return true;
          }

          if (tab === '2') {
            return v.nationality === 'KR'
          }

          if (tab === '3') {
            return v.nationality === 'HK'
          }

          if (tab === '4') {
            return v.nationality === 'JP'
          }

          if (tab === '5') {
            return v.nationality === 'CN'
          }

          if (tab === '6') {
            return v.nationality === 'TW'
          }

          return false;
        })
      } />
    </div>
  );
};

export default ConsultantList;
