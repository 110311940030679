import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc, arrayUnion } from "firebase/firestore";

const uploadApplication = async (caseNo, files) => {
await Promise.all([
    updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo), {
      step_info: {
        step: 2,
        sub_step: 'REC_APPLICATION',
      },
      application_info: {
        files,
      },
      last_updated_at: new Date().getTime(),
      last_updated_at_str: new Date(),
    }),
    updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo, 'histories', 'histories'), {
      histories: arrayUnion({
        step: 2,
        substep: 'REC_APPLICATION',
        timestamp: new Date().getTime(),
      }),
    })
  ]);
};

export default uploadApplication;
