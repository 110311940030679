import { useNavigate } from "react-router-dom";

const ConsultantBaseTable = ({lists}) => {
  const navigate = useNavigate();

  return (
    <div className="table-col">
      <div className="table-header">
        <div className="consultant-base col1">ID No.</div>
        <div className="consultant-base col1">Counsultant</div>
        <div className="consultant-base col3">Grade</div>
        <div className="consultant-base col4">Issued Cases</div>
        <div className="consultant-base col5">Open Cases</div>
        <div className="consultant-base col6">Total Revenue</div>
        <div className="consultant-base col7">Created</div>
        {/* <div className="consultant-base col8">Contact</div> */}
      </div>
      <div className="table-content-consultant">
        {
          lists.length === 0 ? (
            <div className="empty-wrapper">
              <img alt="empty" src="/icon/emptytable.avif" className="empty" />
              <div>There are no consultants registered yet.</div>
            </div>
          ) : <div />
        }
        {
          lists.map((v) => {
            return (
              <div className="table-item" onClick={() => navigate(window.location.pathname + (window.location.search ? window.location.search + '&' : '?') + 'userId=' + v.user_id)}>
                <div className="consultant-base col1 img"><img src={'/icon/flag-kr.png'} />{v.consultant_info.consultant_id}</div>
                <div className="consultant-base col1">{v.consultant_info.name}</div>
                <div className="consultant-base col3 img"><img src={`/icon/${v.consultant_info.grade}.png`} />{v.consultant_info.grade[0].toUpperCase() + v.consultant_info.grade.substring(1)}</div>
                <div className="consultant-base col4">0</div>
                <div className="consultant-base col5">0</div>
                <div className="consultant-base col6">0 USD</div>
                <div className="consultant-base col7">{new Date(v.created_at).toLocaleString()}</div>
                {/* <div className="consultant-base col8">+82 10 6207 7569</div> */}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default ConsultantBaseTable;
