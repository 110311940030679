const splitPhoneNumber = (display) => {
  if (display === null || display === '') return '';

  const baseString =
      display.replaceAll('-', '').replaceAll('+82', '').replaceAll(' ', '');

  if (baseString.length >= 4 && baseString.length < 6) {
    return `${baseString.substring(0, 3)}-${baseString.substring(3)}`;
  } else if (baseString.length === 8) {
    return `${baseString.substring(0, 4)}-${baseString.substring(4)}`;
  } else if (baseString.length >= 6 && baseString.length < 10) {
    if (baseString[0] === '0' && baseString[1] === '2') {
      return `${baseString.substring(0, 2)}-${baseString.substring(2, 5)}-${baseString.substring(5)}`;
    } else {
      return `${baseString.substring(0, 3)}-${baseString.substring(3, 6)}-${baseString.substring(6)}`;
    }
  } else if (baseString.length === 10) {
    if (baseString[0] === '0' && baseString[1] === '2') {
      return `${baseString.substring(0, 2)}-${baseString.substring(2, 6)}-${baseString.substring(6, 10)}`;
    } else {
      return `${baseString.substring(0, 3)}-${baseString.substring(3, 6)}-${baseString.substring(6, 10)}`;
    }
  } else if (baseString.length === 11) {
    return `${baseString.substring(0, 3)}-${baseString.substring(3, 7)}-${baseString.substring(7, 11)}`;
  }

  return display;
};

export default splitPhoneNumber;
