const getListStepLabel = (step, subStep) => {
  if (step === 1) {
    if (subStep === "REQ_PROPOSAL") {
      return 'Proposal Request';
    }
  } else if (step === 2) {
    if (subStep === "REC_PROPOSAL") {
      return 'Proposal Issued';
    }
    if (subStep === "REQ_APPLICATION") {
      return 'Application Request';
    }
    if (subStep === "REC_APPLICATION") {
      return 'Application Issued';
    }
  } else if (step === 3) {
    if (subStep === "SEND_APPLICATION_TO_IFA") {
      return 'Application Sent via Mail';
    }
    if (subStep === "IFA_ACCEPT") {
      return 'Received by IFA';
    }
    if (subStep === "TC_ACCEPT") {
      return 'Received by Trust Company';
    }
    if (subStep === "IC_REVIEW") {
      return 'Underwriting';
    }
    if (subStep === "IC_APPROVE") {
      return 'Policy Approved';
    }
    if (subStep === "IC_IP_ISSUE") {
      return 'Policy Issued';
    }
    if (subStep === "IFA_IP_SEND") {
      return 'Policy Sent by IFA';
    }
  } else if (step === 4) {
    return "Completed";
  }

  return '';
}

module.exports = getListStepLabel;

