import React from 'react';

const Radio = ({active}) => {
  return (
    <div className={`radio-outline ${active ? 'active' : ''}`}>
      <div className={`radio-inner`} />
    </div>
  );
};

export default Radio;