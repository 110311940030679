
export const returnAmountUnit = (amount, unit) => {
  if (unit === 'KRW') {
    if (amount >= 100000000) {
      const value = `${(amount / 100000000).toFixed(2)} 억`;
      return value;
    } else if (amount >= 10000) {
      const value = `${Math.floor((amount / 10000))} 만`;
      return value;
    } else if (amount === 0) {
      return 0;
    } else return amount.toLocaleString();
  } else {
    if (amount > 1000000000) {
      const value = `${(amount / 1000000000).toFixed(2)} b`;
      return value;
    } else if (amount > 1000000) {
      const value = `${(amount / 1000000).toFixed(2)} m`;
      return value;
    } else if (amount > 1000) {
      const value = `${(amount / 1000).toFixed(0)} k`;
      return value;
    } else if (amount === 0) {
      return 0;
    } else return amount.toLocaleString();
  }
};

export const returnMonth = (idx) => {
  if (idx === 1) {
    return 'Jan';
  } else if (idx === 2) {
    return 'Feb';
  } else if (idx === 3) {
    return 'Mar';
  } else if (idx === 4) {
    return 'Apr';
  } else if (idx === 5) {
    return 'May';
  } else if (idx === 6) {
    return 'Jun';
  } else if (idx === 7) {
    return 'Jul';
  } else if (idx === 8) {
    return 'Aug';
  } else if (idx === 9) {
    return 'Sep';
  } else if (idx === 10) {
    return 'Oct';
  } else if (idx === 11) {
    return 'Nov';
  } else if (idx === 12) {
    return 'Dec';
  }
}