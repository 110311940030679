import React, { useEffect, useState } from 'react';

const Toast = ({text, start, setStart, toastName}) => {
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    if (start === toastName && animation === false) {
      setAnimation(true);
      setTimeout(() => {
        setStart('');
        setAnimation(false);
      }, 5000);
    }
  }, [animation, start]);

  useEffect(() => {
    if (start === toastName) {
      setAnimation(false);
      setStart('');
      setAnimation(true);
    }
  }, [start]);

  return (
    <div className={'toast-wrap' + (animation ? ' active' : '')}>
      <div className="toast">
        <div style={{ whiteSpace: 'pre' }}>{text}</div>
      </div>
    </div>
  );
};

export default Toast;