import { ResponsiveLine } from '@nivo/line';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { returnAmountUnit, returnMonth } from '../../utils/function/function';
import GradeModal from '../../components/modal/grade_modal';
import Toast from '../../components/common/toast';
import CalendarFromModal from '../../components/modal/calendar_from_modal';
import CalendarToModal from '../../components/modal/calendar_to_moda';
import splitPhoneNumber from '../../utils/format/split_phone_number';

const ConsultantDetail = ({userData}) => {
  const navigate = useNavigate();
  const [toast, setToast] = useState('');

  const [amount, setAmount] = useState([0, 0, 0, 0, 0]);

  const generateLastNMonthsData = (n) => {
    const data = [];
    const now = new Date();
    for (let i = n - 1; i >= 0; i--) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      // const amount = [90000000, 137000000, 120000000, 111000000, 102000000];
      // const amount = Math.floor(Math.random() * 1000 + (1000 * i));
      data.push({ x: `${returnMonth(date.getMonth() + 1)}`, y: amount[i] });
    }
    return data;
  }

  const data = [
    {
      id: "series1",
      data: generateLastNMonthsData(5),
    }
  ];
  
  const [gradeModal, setGradeModal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(1);
  const openGradeModal = () => {
    setGradeModal(true);
  };
  const gradeModalCancel = () => {
    setGradeModal(false);
  };
  const gradeModalSave = () => {
    setGradeModal(false);
    setToast('gradeSave');
  };
  
  const [calendarFromModal, setCalendarFromModal] = useState(false);
  const openCalendarFromModal = () => {
    setCalendarFromModal(true);
  };
  const calendarFromModalCancel = () => {
    setCalendarFromModal(false);
  };
  const calendarFromModalSave = () => {
    setCalendarFromModal(false);
  };
  
  const [calendarToModal, setCalendarToModal] = useState(false);
  const openCalendarToModal = () => {
    setCalendarToModal(true);
  };
  const calendarToModalCancel = () => {
    setCalendarToModal(false);
  };
  const calendarToModalSave = () => {
    setCalendarToModal(false);
  };

  const [dayFrom, setDayFrom] = useState();
  const [dayTo, setDayTo] = useState();

  return (
    <div className="taskdetail-container">
      <Toast
        text={'The grade has been successfully updated.'}
        start={toast}
        setStart={setToast}
        toastName="gradeSave"
      />
      <GradeModal
        open={gradeModal}
        cancel={gradeModalCancel}
        save={gradeModalSave}
        selected={selectedGrade}
        setSelected={setSelectedGrade}
      />
      <CalendarFromModal
        open={calendarFromModal}
        cancel={calendarFromModalCancel}
        setDayFrom={setDayFrom}
        dayTo={dayTo}
      />
      <CalendarToModal
        open={calendarToModal}
        cancel={calendarToModalCancel}
        setDayTo={setDayTo}
        dayFrom={dayFrom}
      />
      <div className='back-btn' onClick={() => navigate(-1)}>
        <img src='/icon/arrow-back.png' alt='back' />
        Back to List
      </div>
      <div className='taskdetail-head-container'>
        <div className='consultant-detail-head-wrap'>
          <div className='taskdetail-head-title-wrap'>
            <img src='/icon/flag-kr.png' alt='nationality' />
            <div className='taskdetail-title'>{userData.consultant_info.name} #{userData.consultant_info.consultant_id}</div>
            <div style={{fontSize: '16px', fontWeight: 'normal'}}>({userData.user_id})</div>
          </div>
          <div className='detail-info-table-btn' onClick={() => openGradeModal()}>
            <img src={'/icon/change.png'} />
            <span>Change Grade</span>
          </div>
        </div>
        <div className='taskdetail-table'>
          <div className='taskdetail-table-head-wrap'>
            <div className='consultant-detail-table-head th-grade'>Grade</div>
            <div className='consultant-detail-table-head th-issue'>Issued Cases</div>
            <div className='consultant-detail-table-head th-open'>Open Cases</div>
            <div className='consultant-detail-table-head th-revenue'>Total Revenue</div>
            <div className='consultant-detail-table-head th-date'>last opened</div>
            <div className='consultant-detail-table-head th-contact'>Contact</div>
          </div>
          <div className='taskdetail-table-body-wrap'>
            <div className='consultant-detail-table-body th-grade'>
              <img src={`/icon/${userData.consultant_info.grade}.png`} />
              <div>{userData.consultant_info.grade[0].toUpperCase() + userData.consultant_info.grade.substring(1)}</div>
            </div>
            <div className='consultant-detail-table-body th-premium'>0</div>
            <div className='consultant-detail-table-body th-customer'>0</div>
            <div className='consultant-detail-table-body th-consultant'>0 USD</div>
            <div className='consultant-detail-table-body th-date'>2024-12-30 17:00</div>
            <div className='consultant-detail-table-body th-status'>{splitPhoneNumber(userData.consultant_info.phone_number)}</div>
          </div>
        </div>
      </div>
      <div className='consultant-detail-body-container'>
        <div className='consultant-detail-body-title'>Work Overview</div>
        <div className='consultant-detail-body-date-wrap'>
          <div className='consultant-detail-body-date' onClick={() => openCalendarFromModal()}>
            <div>From 2024. 2. 1 ~</div>
            <img src={'icon//arrow-down-small.png'} />
          </div>
          <div className='consultant-detail-body-date' onClick={() => openCalendarToModal()}>
            <div>To 2024. 2. 1</div>
            <img src={'icon//arrow-down-small.png'} />
          </div>
        </div>
        <div className='consultant-detail-policy-sold-container'>
          <div className='consultant-detail-body-graph-head'>
            <div className='consultant-detail-body-graph-head-title'>
              <img src={'/icon/sold-policies.png'} />
              <div>Accounting Records</div>
            </div>
          </div>
          <div className='consultant-policy-table'>
            <div className='taskdetail-table-head-wrap'>
              <div className='consultant-policy-table-head th-date'>Date</div>
              <div className='consultant-policy-table-head th-customer'>Customer</div>
              <div className='consultant-policy-table-head th-profit'>Revenue</div>
              <div className='consultant-policy-table-head th-earn'>Commission</div>
              <div className='consultant-policy-table-head th-sharing'>Transfer Date</div>
              <div className='consultant-policy-table-head th-product'>Product Name</div>
            </div>
            <div className="empty-wrapper">
              <img alt="empty" src="/icon/emptytable.avif" className="empty" />
              <div>There are no data yet.</div>
            </div>
            {/* <div className='consultant-policy-table-body-wrap'>
              <div className='consultant-policy-table-body th-date'>24.3.12</div>
              <div className='consultant-policy-table-body th-customer'>고객명#1532</div>
              <div className='consultant-policy-table-body th-profit'>100,000 USD</div>
              <div className='consultant-policy-table-body th-earn'>29,800 USD</div>
              <div className='consultant-policy-table-body th-sharing'>229.8 %</div>
              <div className='consultant-policy-table-body th-product'>Wealth Advance Savings Series II - Ultimate(100,000 USD)</div>
            </div>
            <div className='consultant-policy-table-body-wrap'>
              <div className='consultant-policy-table-body th-date'>24.3.12</div>
              <div className='consultant-policy-table-body th-customer'>고객명#1532</div>
              <div className='consultant-policy-table-body th-profit'>100,000 USD</div>
              <div className='consultant-policy-table-body th-earn'>29,800 USD</div>
              <div className='consultant-policy-table-body th-sharing'>229.8 %</div>
              <div className='consultant-policy-table-body th-product'>Wealth Advance Savings Series II - Ultimate(100,000 USD)</div>
            </div> */}
          </div>
        </div>
        <div className='consultant-detail-body-graph-wrap'>
          <div className='consultant-detail-body-graph-head'>
            <div className='consultant-detail-body-graph-head-title'>
              <img src={'/icon/revenue.png'} />
              <div>Revenue</div>
            </div>
            <div className='consultant-detail-body-graph-head-amount'>0 USD</div>
          </div>
          
          <div className='consultant-detail-graph-wrap'>
            <ResponsiveLine
              data={data}
              margin={{ top: 12, right: 24, bottom: 36, left: 72 }}
              xScale={{ type: 'point' }}
              yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: true,
                  reverse: false
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  tickSize: 0,
                  tickPadding: 9,
                  tickRotation: 0,
                  // legend: 'transportation',
                  legendOffset: 36,
                  legendPosition: 'middle',
                  truncateTickAt: 0
              }}
              axisLeft={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  // legend: 'count',
                  legendOffset: -40,
                  legendPosition: 'middle',
                  truncateTickAt: 0,
                  // tickValues: tickReturn(),
                  // format: (value) => `${returnAmountUnit(value, switchActive2 ? 'HKD' : switchActive2 ? 'HKD' : 'KRW')}`,
                  format: (value) => returnAmountUnit(value, 'USD'),
              }}
              colors={['#30EA8D', '#000']}
              lineWidth={1}
              pointColor={{ from: 'color', modifiers: [] }}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="data.yFormatted"
              pointLabelYOffset={-12}
              enableArea={true}
              areaOpacity={0.1}
              enableTouchCrosshair={true}
              useMesh={true}
              legends={[]}
              theme={{
                axis: {
                  domain: {
                      line: {
                          strokeWidth: 1
                      }
                  },
                  ticks: {
                    text: {
                      fontSize: 12, // x축, y축 단위 폰트 크기 설정
                      fill: '#5C5F6B'
                    }
                  },
                },
                grid: {
                    line: {
                        stroke: "#DADDE5",
                        strokeWidth: 1
                    }
                },
              }}
            />
          </div>
        </div>
        <div className='consultant-detail-body-graph-wrap'>
          <div className='consultant-detail-body-graph-head'>
            <div className='consultant-detail-body-graph-head-title'>
              <img src={'/icon/commission.png'} />
              <div>Commission</div>
            </div>
            <div className='consultant-detail-body-graph-head-amount'>0 USD</div>
          </div>
          <div className='consultant-detail-graph-wrap'>
            <ResponsiveLine
              data={data}
              margin={{ top: 12, right: 24, bottom: 36, left: 72 }}
              xScale={{ type: 'point' }}
              yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: true,
                  reverse: false
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  tickSize: 0,
                  tickPadding: 9,
                  tickRotation: 0,
                  // legend: 'transportation',
                  legendOffset: 36,
                  legendPosition: 'middle',
                  truncateTickAt: 0
              }}
              axisLeft={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  // legend: 'count',
                  legendOffset: -40,
                  legendPosition: 'middle',
                  truncateTickAt: 0,
                  // tickValues: tickReturn(),
                  // format: (value) => `${returnAmountUnit(value, switchActive2 ? 'HKD' : switchActive2 ? 'HKD' : 'KRW')}`,
                  format: (value) => returnAmountUnit(value, 'USD'),
              }}
              colors={['#30EA8D', '#000']}
              lineWidth={1}
              pointColor={{ from: 'color', modifiers: [] }}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="data.yFormatted"
              pointLabelYOffset={-12}
              enableArea={true}
              areaOpacity={0.1}
              enableTouchCrosshair={true}
              useMesh={true}
              legends={[]}
              theme={{
                axis: {
                  domain: {
                      line: {
                          strokeWidth: 1
                      }
                  },
                  ticks: {
                    text: {
                      fontSize: 12, // x축, y축 단위 폰트 크기 설정
                      fill: '#5C5F6B'
                    }
                  },
                },
                grid: {
                    line: {
                        stroke: "#DADDE5",
                        strokeWidth: 1
                    }
                },
              }}
            />
          </div>
        </div>
        {/* <div className='consultant-detail-body-graph-wrap'>
          <div className='consultant-detail-body-graph-head'>
            <div className='consultant-detail-body-graph-head-title'>
              <img src={'/icon/policy.png'} />
              <div>Policies Sold</div>
            </div>
            <div className='consultant-detail-body-graph-head-amount'>10,000,000 USD</div>
          </div>
          <div className='consultant-detail-graph-wrap'>
            <ResponsiveLine
              data={data}
              margin={{ top: 12, right: 24, bottom: 36, left: 72 }}
              xScale={{ type: 'point' }}
              yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: true,
                  reverse: false
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  tickSize: 0,
                  tickPadding: 9,
                  tickRotation: 0,
                  // legend: 'transportation',
                  legendOffset: 36,
                  legendPosition: 'middle',
                  truncateTickAt: 0
              }}
              axisLeft={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickRotation: 0,
                  // legend: 'count',
                  legendOffset: -40,
                  legendPosition: 'middle',
                  truncateTickAt: 0,
                  // tickValues: tickReturn(),
                  // format: (value) => `${returnAmountUnit(value, switchActive2 ? 'HKD' : switchActive2 ? 'HKD' : 'KRW')}`,
                  format: (value) => returnAmountUnit(value, 'USD'),
              }}
              colors={['#30EA8D', '#000']}
              lineWidth={1}
              pointColor={{ from: 'color', modifiers: [] }}
              pointBorderColor={{ from: 'serieColor' }}
              pointLabel="data.yFormatted"
              pointLabelYOffset={-12}
              enableArea={true}
              areaOpacity={0.1}
              enableTouchCrosshair={true}
              useMesh={true}
              legends={[]}
              theme={{
                axis: {
                  domain: {
                      line: {
                          strokeWidth: 1
                      }
                  },
                  ticks: {
                    text: {
                      fontSize: 12, // x축, y축 단위 폰트 크기 설정
                      fill: '#5C5F6B'
                    }
                  },
                },
                grid: {
                    line: {
                        stroke: "#DADDE5",
                        strokeWidth: 1
                    }
                },
              }}
            />
          </div>
        </div> */}
        
      </div>
    </div>
  );
};

export default ConsultantDetail;