import React from 'react';
import Radio from '../common/radio';

const GradeModal = ({open, cancel, save, selected, setSelected}) => {

  const clickItem = (idx) => {
    setSelected(idx);
  };

  return (
    <>
      {
        open ?
        <div className='modal-overlay'>
          <div className='state-modal-container'>
            <div className='state-modal-body'>
              <div className='state-modal-body-title'>Select the grade to change, then click 'Save'.</div>
              <div className='state-modal-select-wrap'>
                <div className='state-modal-select-item' onClick={() => clickItem(1)}>
                  <Radio active={selected === 1} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/yellow.png'} />
                    Yellow
                </div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(2)}>
                  <Radio active={selected === 2} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/white.png'} />
                    White
                </div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(3)}>
                  <Radio active={selected === 3} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/red.png'} />
                    Red
                </div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(4)}>
                  <Radio active={selected === 4} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/blue.png'} />
                    Blue
                </div>
                </div>
                <div className='state-modal-select-item' onClick={() => clickItem(5)}>
                  <Radio active={selected === 5} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/purple.png'} />
                    Purple
                </div>
                </div>
                <div className='state-modal-select-item last' onClick={() => clickItem(6)}>
                  <Radio active={selected === 6} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/black.png'} />
                    Black
                </div>
                </div>
                {/* <div className='state-modal-select-item last' onClick={() => clickItem(7)}>
                  <Radio active={selected === 7} /><div className='grade-modal-select-item-wrap'>
                    <img src={'/icon/silver.png'} />
                    Silver
                </div>
                </div> */}
              </div>
            </div>
            <div className='upload-modal-foot'>
              <div className='upload-btn-cancel' onClick={() => cancel()}>Cancel</div>
              <div className='upload-btn-save' onClick={() => save()}>Save</div>
            </div>
          </div>
        </div> : null
      }
    </>
  );
};

export default GradeModal;