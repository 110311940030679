import React from 'react';

const ActionRequired = ({type, title, text, btn, status}) => {
  return (
    <div className='task-action-container'>
      <div className='task-action-text-wrap'>
        <div className='task-action-title'>{title}</div>
        <div className='task-action-text'>{text}</div>
        {
          type === 'stateChange' ?
          <div className='task-action-state-table'>
            <div className='task-action-state-table-head'>Current Status</div>
            <div className='task-action-state-table-body'>{status}</div>
          </div> : null
        }
      </div>
      <div className='task-action-btn' onClick={btn.function}>{btn.name}</div>
    </div>
  );
};

export default ActionRequired;